import Vue from "vue";
import Vuex from "vuex";
import base from "./modules/base-module";
import details from "./modules/details-page-module";
import pr from "./modules/pull-requests-page-module";
import projects from "./modules/projects-page-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    "base-store": base,
    "details-page": details,
    "pull-requests-page": pr,
    "projects-page": projects,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
