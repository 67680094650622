<template>
  <v-app>
    <NavBar/>
    <v-main>
      <slot >
        <v-progress-circular
          style="margin: auto"
          :size="500"
          :width="10"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </slot>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    //Unauthorized,
    NavBar,
    Footer,
  },
};
</script>
