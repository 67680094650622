<template>
  <div>
    <v-app-bar app>
      <v-toolbar-title @click="navigateTo('/')">
        <div style="display: flex; align-items: center;">
          <svg-icon style="transform: scale(1.2); margin-right: 10px;" type="mdi" :path="pathBalance"></svg-icon>
          <div>{{ appTitleLine1 }}</div>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text v-for="item in menuItems" :key="item.title" @click="navigateTo(item.path)" onclick="location.reload()" :to="item.path">
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-md-and-up">
        <v-btn @click="openDrawer" class="hidden-md-and-up">
          <svg-icon type="mdi" :path="pathMenu"></svg-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="sidebar" right>
      <v-list>
        <v-list-item v-for="item in menuItems" :key="item.title" @click="navigateTo(item.path)" onclick="location.reload()" :to="item.path">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiScaleBalance, mdiMenu } from '@mdi/js';

export default {
  name: "nav-bar",
  components: {
    SvgIcon,
  },
  data() {
    return {
      pathBalance: mdiScaleBalance,
      pathMenu: mdiMenu,
      appTitleLine1: "Grile drept online",
      sidebar: false,
      menuItems: [
        { title: "Acasa", path: "/" },
        { title: "Abonamente", path: "/abonamente" },
        { title: "Despre Noi", path: "/aboutUs" },
        { title: "Contact", path: "/contact" },
        { title: "Contul meu", path: "/contulMeu" },
      ],
    };
  },
  methods: {
    openDrawer(){
      this.sidebar = true;
    },
    navigateTo(path) {
      this.$router.push(path);
      this.sidebar = false; // Close sidebar after navigation on mobile
    },
  },
};
</script>

<style scoped>
.v-btn--active {
  color: #fff;
}
.v-btn:hover::before {
  background-color: #1a4296;
}
.v-btn--active::before {
  background-color: #1a4296;
  opacity: 1;
}
</style>
