<template>
    <v-toolbar height="300" style="background-color: #F4906C">
      <v-col>
        <v-row>
          <v-col></v-col>
          <v-col>
            <div style="display:flex;">
              <svg-icon style="color: white; scale: 2; margin-top: 15px; margin-left: 10px;" type="mdi" :path="path"></svg-icon>
              <div style="margin-left: 20px;">
                <h4 style="color: white; margin-top: 15px; margin-bottom: -10px;">{{ appTitleLine1 }}</h4>
                <!-- <br>
                <h4 style="color: white; margin-top: -10px;">{{ appTitleLine2 }}</h4> -->
              </div>          
            </div>
          </v-col>
          <v-col></v-col>
          <v-col>
            <v-col style="display:inline-block">
              <router-link to="/terms" tag="span" style="cursor: pointer">
                <a style="color: white;">Termeni si conditii</a>
              </router-link>
              <v-row><br><br></v-row>
              <router-link to="/privacy" tag="span" style="cursor: pointer">
                <a style="color: white;">Politica de confidentialitate</a>
              </router-link>
            </v-col>
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-divider color="white"></v-divider>
        </v-row>
        <v-row class="justify-center align-center">
          <p style="color: white; margin-top: 20px;">Copyright © Mihai Adam</p>
        </v-row>
      </v-col>
    </v-toolbar>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiScaleBalance } from '@mdi/js';

export default {
  name: "page-footer",
  components:{
    SvgIcon,
  },
  methods:{},
  data() {
    return {
      path: mdiScaleBalance,
      appTitleLine1: "Grile drept online",
      //appTitleLine2: "INM-Barou-Notari",
    };
  },
};
</script>
