import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import baseplugin from "../plugins/base-plugin";
import DefaultLayout from "../layouts/default-layout";
import vSelect from "vue-select";

// Plugins
Vue.use(baseplugin);
Vue.use(VueSweetalert2);

// Components
Vue.component("default-layout", DefaultLayout);
Vue.component("v-custom-select", vSelect);
