import Vue from "vue";
import App from "./pages/App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./utils/vue-settings";
import "./assets/site.scss";

if (process.env.NODE_ENV !== "development") {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
} else {
  Vue.config.productionTip = false;
}

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
