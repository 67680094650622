import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/privacy",
    name: "privacy-page",
    component: () => import(/* webpackChunkName: "about" */ "../pages/views/PrivacyPolicyPage.vue"
      ),
  },
  {
    path: "/terms",
    name: "terms-page",
    component: () => import(/* webpackChunkName: "about" */ "../pages/views/TermsAndConditionsPage.vue"
      ),
  },
  {
    path: "/pay",
    name: "pay-page",
    component: () => import(/* webpackChunkName: "about" */ "../pages/views/PayPage.vue"
      ),
  },
  {
    path: "/testQuestions",
    name: "test-questions-page",
    component: () => import(/* webpackChunkName: "about" */ "../pages/views/TestQuestionsPage.vue"
      ),
  },
  {
    path: "/questions",
    name: "questions-page",
    component: () => import(/* webpackChunkName: "about" */ "../pages/views/QuestionsPage.vue"
      ),
    props: true 
  },
  {
    path: '/contulMeu',
    name: 'myAccount-page',
    component: () => import(/* webpackChunkName: "about" */ '../pages/views/MyAccountPage.vue'),
  },
  {
    path: '/abonamente',
    name: 'subscriptions-page',
    component: () => import(/* webpackChunkName: "about" */ '../pages/views/SubscriptionsPage.vue'),
  },
  {
      path: '/contact',
      name: 'contact-page',
      component: () => import(/* webpackChunkName: "about" */ '../pages/views/ContactPage.vue'),
  },
  {
    path: "/aboutUs",
    name: "aboutUs-page",
    component: () => import(/* webpackChunkName: "about" */ "../pages/views/AboutUsPage.vue"),
  },
  {
    path: "/",
    name: "home-page",
    component: () => import( /* webpackChunkName: "about" */ "../pages/views/HomePage.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
