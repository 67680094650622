const module = {
  namespaced: true,

  state: {
    loggedIn: false,
    roles: [],
    accessToken: null,
  },
  getters: {
    isAuthenticated: (state) => {
      return state.loggedIn;
    },
    getRoles: (state) => {
      return state.roles;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
  },
  mutations: {
    loginSuccess(state, response) {
      state.loggedIn = true;
      state.roles = response.roles;
      state.accessToken = response.accessToken;
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.roles = [];
    },
    logout(state) {
      state.loggedIn = false;
      state.roles = [];
    },
  },
  actions: {
    loginSuccess({ commit }, response) {
      commit("loginSuccess", response);
    },
    logout({ commit }) {
      commit("logout");
    },
    loginFailure({ commit }) {
      commit("loginFailure");
    },
  },
};

export default module;
