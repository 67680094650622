<template>
  <default-layout>
    <router-view />
  </default-layout>
</template>
<script>
// firebase login
// firebase deploy --only hosting:griledreptonline

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkXOajAHj4uoBzz5hNap_kxq658vgHuQw",
  authDomain: "grile-drept-online.firebaseapp.com",
  projectId: "grile-drept-online",
  storageBucket: "grile-drept-online.appspot.com",
  messagingSenderId: "1053237664429",
  appId: "1:1053237664429:web:7b27a8ec0c963fdc98c424",
  measurementId: "G-9M0GWP89GP"
};


export default {
  name: "App",
  data: () => ({
    //
  }),
  async mounted() {
    let redirectPath = sessionStorage.getItem("redirectPath");
    let router = this.$router;
    if (redirectPath) {
      // Define function to be used as callback
      let redirectFunc = function () {
        sessionStorage.removeItem("redirectPath");
        router.push(redirectPath);
      };
      redirectFunc();
    }
  },
  async created() {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const analytics = getAnalytics(app);
    console.log(analytics);
    console.log(db);
  }
};
</script>
