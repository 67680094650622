import { Sweetalert2Icons } from "../utils/constants";
import moment from "moment";

const defaultSwalOptions = {
  icon: Sweetalert2Icons.INFO,
  title: "",
  html: "",
  showConfirmButton: true,
  showCancelButton: false,
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  customClass: {
    container: "container-swal-modal",
  },
  position: "center",
};
const basePlugin = {
  install: function (Vue) {
    Vue.showToast = Vue.prototype.$showToast = function (
      type,
      text,
      title = "",
      duration = 5000
    ) {
      title = (title || type || "").toUpperCase();
      return Vue.swal.fire({
        icon: type,
        title: title,
        text: text,
        toast: true,
        timer: duration,
        position: "top-end",
        showClass: "swall-top",
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          container: "container-swal-modal",
        },
        didOpen: (toast) => {
          toast.addEventListener("click", Vue.swal.clickConfirm);
          toast.addEventListener("mouseenter", Vue.swal.stopTimer);
          toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
        },
      });
    };
    Vue.promiseDialog = function (options) {
      let dialogSettings = Object.assign({}, defaultSwalOptions, options);
      return Vue.swal.fire(dialogSettings);
    };

    Vue.showSimpleDialog = Vue.prototype.$showSimpleDialog = function (
      icon,
      text,
      title = "",
      confirmButtonText = "OK"
    ) {
      let dialogSettings = Object.assign({}, defaultSwalOptions, {
        icon,
        text,
        title,
        confirmButtonText,
      });
      return Vue.promiseDialog(dialogSettings);
    };

    Vue.showYesNoDialog = Vue.prototype.$showYesNoDialog = function (
      html,
      title = "",
      confirmButtonText = "Yes",
      cancelButtonText = "No"
    ) {
      let dialogSettings = Object.assign({}, defaultSwalOptions, {
        icon: Sweetalert2Icons.QUESTION,
        html,
        title,
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
      });
      return Vue.promiseDialog(dialogSettings).then((result) => {
        return result.value;
      });
    };

    Vue.convertUTCToLocalTime = Vue.prototype.$convertToLocalTime = function (
      date,
      format = "DD.MM.YYYY, HH:mm"
    ) {
      return moment.utc(date).local().format(format);
    };

    Vue.filter("formatNumber", function (value) {
      var formatter = new Intl.NumberFormat("ro-RO", {
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    });

    Vue.filter("formatDate", function (value) {
      if (!value) return "";
      return Vue.convertUTCToLocalTime(value, "DD.MM.YYYY");
    });

    Vue.filter("formatDateTime", function (value) {
      if (!value) return "";
      return Vue.convertUTCToLocalTime(value);
    });
  },
};
export default basePlugin;
